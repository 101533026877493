<template>
  <div>
    <head-layout   :head-title=" $t('cip.dc.dataSubject.title')"  :head-btn-options="headBtnOptions" @head-add="addFn">

    </head-layout>

    <el-container class="new-sino-box">




      <el-collapse v-model="activeName" style="width: 100%;">
  <el-collapse-item v-for="(item,index) in data" :key="index" :name="item.id">
    <template slot="title">
      <div style="display: flex;justify-content: space-between;width: 100%;" @mouseenter="showDel(item)" @mouseleave="nodel(item)">
        <div>
        <i style="font-size: 20px;font-style: normal;font-weight: bold;"> {{ item.name }} </i>
      </div>

      <div>
        <span style="margin-right: 15px;">{{ $t('cip.dc.datamapDComission.all') }}
       {{ item.tableCount }} {{ $t('cip.dc.datamapDComission.tableCount') }}{{ item.dataObjectCount }}{{ $t('cip.dc.datamapDComission.dataObjectCount') }}{{ item.indexCount }}{{ $t('cip.dc.datamapDComission.indexCount') }}</span>
       <span v-if="item.del" class="el-icon-delete" @click.stop="deleteFn(item)" style="color: red;font-size: 20px;margin-right: 10px; cursor: pointer;" ></span>
      </div>
      </div>


      <!-- <el-button type="text"  style="margin-left: 20px;" @click="deleteFn(item)">删除</el-button> -->
    </template>

      <div style="display: flex;flex-wrap: wrap;">
        <div v-for="(item2,index2) in item.children" :key="index2" style="margin: 15px; width: 200px; height: 160px; border: 1px solid #DCDFE6;border-radius: 8px;" @mouseenter="showDel(item2)" @mouseleave="nodel(item2)">
          <div style="display: flex;justify-content: space-between;align-items: center;height: 38px;line-height: 38px;">
            <span style="font-size: 18px;font-style: normal;font-weight: bold; margin-left: 10px;"> {{ item2.name }} </span>
          <span v-if="item2.del" class="el-icon-delete" @click="deleteFn(item2)" style="color: red;font-size: 20px;margin-right: 10px; cursor: pointer;" ></span>
          </div>


          <hr style="background-color: #DCDFE6;height: 1px;border: none;margin: 0;">
          <div style="display: flex;justify-content: space-between;height: 38px;line-height: 38px;font-size: 15px;">
            <span style="margin-left: 20px;"><i class="el-icon-date"></i>  {{ $t('cip.dc.datamapDComission.tableCount') }}   </span>

            <span style="font-weight: 700;color: skyblue;margin-right: 15px;font-size: 20px;color: rgb(70, 162, 247);cursor: pointer;" @click="todataDiercrtory(item2)"> {{ item2.tableCount }}</span>

          </div>

          <div style="display: flex;justify-content: space-between;height: 38px;line-height: 38px;font-size: 15px;">
            <span style="margin-left: 20px;"><i class="el-icon-coin"></i>  {{ $t('cip.dc.datamapDComission.dataObjectCount') }}   </span>

            <span style="font-weight: bold;color: skyblue;margin-right: 15px;font-size: 20px;color: rgb(70, 162, 247);cursor: pointer;" @click="todataobject(item2)">
              {{ item2.dataObjectCount }}</span>
          </div>

          <div style="display: flex;justify-content: space-between;height: 38px;line-height: 38px;font-size: 15px;">
            <span style="margin-left: 20px;"><i class="el-icon-postcard"></i>  {{ $t('cip.dc.datamapDComission.indexCount') }}   </span>

            <span style="font-weight: bold;color: skyblue;margin-right: 15px;font-size: 20px;color: rgb(70, 162, 247);cursor: pointer;" @click="todatazb(item2)">
              {{ item2.indexCount }}   </span>
          </div>






        </div>
        <div style="margin: 15px; width: 200px; height: 160px; border: 1px solid #DCDFE6;border-radius: 8px; text-align: center;">
          <i class="el-icon-plus" style="font-size: 36px;line-height: 160px;font-weight: bold;" @click="addFn(item)"></i>
        </div>

      </div>
  </el-collapse-item>

</el-collapse>






    </el-container>

    <el-dialog
      :title="$t('cip.cmn.btn.addBtn') "
      :visible.sync="dialogVisible"
      width="50%"
      @close="closedialog"
    >
      <span>
         <el-form  :model="form" label-width="120px" :rules="rules" ref="ruleForm">
            <el-form-item :label="$t('cip.dc.dataSubject.field.name')" prop="name">
              <el-input :placeholder="$t('cip.dc.dataSubject.field.name')" class = 'new-form-input' v-model="form.name"  autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('cip.dc.dataSubject.field.codeName')" prop="ename">
              <el-input  :placeholder="$t('cip.dc.dataSubject.field.codeName')" class = 'new-form-input' v-model="form.ename"  autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('cip.dc.datamapDComission.indexzt')" >
              <!-- <el-input  :placeholder="$t('cip.dc.dataSubject.field.Englishname')" class = 'new-form-input' v-model="form.ename"  autocomplete="off"></el-input> -->
              <el-switch
  v-model="form.indexShow"
  :active-text="$t('cip.dc.datamapDComission.yes')"
  active-value="1"
  inactive-value="0"
  :inactive-text="$t('cip.dc.datamapDComission.no')">
</el-switch>
            </el-form-item>
            <el-form-item :label="$t('cip.dc.dataSubject.field.Parent')" >
              <span>{{activeTree.name}}</span>
            </el-form-item>
      </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
        <el-button type="primary"   style="margin-left: 10px" @click="headSave">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index"
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {
  createUdateDataDirectory,
  deleteDataDirectoryById,
  getDataDirectoryAllByPid,
  getDataDirectoryById
} from "@/api/dataAcquisition/DataAssets";

export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
    CommonTree
  },
  watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
  data() {
    return {
      option:{
        lazy: true,
        rowKey: 'id',
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        activeName:[],
        column: [
          {
            label: this.$t('cip.dc.dataSubject.field.name'),
            prop: "name",
            search: true,
            rules: [{
              required: true,
              message:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.dept.field.deptName"),
              trigger: "blur"
            }]
          },





        ]
      },
      gridRowBtn: [
         {
          label:this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          type: "text",
          icon: ""
        },
        {
          label:this.$t("cip.dc.dataSubject.btn.AddChild"),
          emit: "grid-addChild",
          type: "text",
          icon: "",
        },
      ],
      filterText:"",
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:10,
        },
        param1:''
      },
      loading:false,
      // treeOptions:[{
      //   label: this.$t(`cip.cmn.btn.addBtn`),
      //   emit: "head-add",
      //   type: "button",
      //   icon: ""
      // }],
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      },

      // {
      //   label: this.$t("cip.cmn.btn.delBtn"),
      //   emit: "head-romve",
      //   type: "button",
      //   icon: ""
      // }
    ],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible:false,
      rules: {
        name: [
          { required: true, message:  this.$t("cip.dc.dataSubject.rules.name"), trigger: 'change' }
        ],
        ename: [
          { required: true, message: this.$t("cip.dc.dataSubject.rules.ename"), trigger: 'change' }
        ],
      },
      form: {
        id:'',
        pid:'0',
        name:'',
        ename:'',
        url:'',
        indexShow:0
      },
      formLabelWidth: '120px',
      activeTree:{}
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    filterNode(value, data) {
        if (!value) return true;
        console.log(data,"ccccc");
        return data.name.indexOf(value) !== -1;
      },
    handleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.form.pid = data.id;
        this.$refs.tree.setCheckedKeys([data.id])
      }
    },
    handleNodeClick(data) {
      console.log(data);
    },
    getDataDirectoryByIdFn(id){
      getDataDirectoryById({id:id}).then((res)=>{
        this.form = res.data.data;
      })
    },
    headSave(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$loading();
          if(this.form.id){
            createUdateDataDirectory(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.offlineDComission.editmsg'));
              this.onLoad()
              this.dialogVisible = false;
              this.$loading().close();
            })
          }else{
            createUdateDataDirectory(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.offlineDComission.savemsg'));
              this.onLoad()
              this.dialogVisible = false;
              this.$loading().close();
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onLoad() {
      this.loading = true;
      getDataDirectoryAllByPid({}).then(res => {
        this.loading = false;
        this.data = res.data.data;

        this.data.forEach(element => {
          this.$set(element, "del", false)
          element.children.forEach(element2 => {
            this.$set(element2, "del", false)
          });
        });

        if(this.pagetime==1){
          this.activeName = this.data[0].id
          this.pagetime++
        }



      });
    },
    addFn(item){
      if(!item){
      this.dialogVisible = true;
      this.activeTree = {}
      this.form =  {
        id:'',
        pid:'0',
        name:'',
        ename:'',
        url:'',
        indexShow:0
      }
      }
      if(item){
        this.dialogVisible = true;
        this.form.pid = item.id;
        this.activeTree = item;
      }

    },
    handleEdit(index,item){
      this.$router.push({
        path: '/DataAssets/dataSubjectAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
    },
    deleteFn(data){
      this.$confirm(this.$t('cip.dc.dataSubject.msg.msg1'), this.$t('cip.dc.dataSubject.msg.Tips'), {
        confirmButtonText: this.$t('projectlist.btn.sure'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning'
      }).then(() => {
        deleteDataDirectoryById({id:data.id}).then((res)=>{
          this.$message.success(this.$t('cip.dc.dataSubject.msg.msg2'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.size = val;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.queryList.page = val;
      this.onLoad()
    },
    showDel(e){

      e.del = true
    },
    nodel(e){
      e.del = false
    },

    closedialog(){
      this.$refs["ruleForm"].resetFields();
      this.form={
        id:'',
        pid:'0',
        name:'',
        ename:'',
        url:'',
        indexShow:0
      }
    },
    todataDiercrtory(item){
    this.$router.push({
        path: '/business/datacenter/DataAssets/DataDirectory',
        query: {
          pid:item.pid,
          id:item.id
        }
      })
    },
    todataobject(item){
    this.$router.push({
        path: '/business/datacenter/dataObject/index',
        query: {
          pid:item.pid,
          id:item.id
        }
      })
    },
    todatazb(item){
    this.$router.push({
        path: '/business/index/appIndex/index',
        query: {
          pid:item.pid,
          id:item.id
        }
      })
    },



  },
  created() {
    this.onLoad()
    this.pagetime = 1

  },
  mounted() {
  },
};
</script>

<style scoped>
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>
